import React from 'react'
import './style.sass'
import {Link} from "react-router-dom";

export default function SiteFooter(props) {
    const rout = window.location.href
    const start = rout.indexOf('/startPage')
    return (
        <footer className="footer" style={(props.style && props.style)}>
            <div className="footer__box">
                {/*<div className="footer__rights">*/}
                {/*    &copy; {new Date().getFullYear()} Mars Incorporated.<br/> Все права защищены.*/}
                {/*</div>*/}
                <div className="footer__policy">
                    <div className="footer__policy__box">
                        <div className="footer__item">
                            &copy; 2022 Марс или аффилированные лица.
                        </div>
                        <div className="footer__item">
                            Все права защищены.
                        </div>
                    </div>
                    <div className="footer__policy__box">
                        <div className="footer__item">
                            <a href="https://www.mars.com/privacy-policy-russia" className="footer__link" target="_blank" rel="noopener noreferrer">Положение о конфиденциальности</a>
                        </div>
                        <div className="footer__item">
                            <a href="https://www.mars.com/legal-russia" className="footer__link" target="_blank" rel="noopener noreferrer">Правила использования сайта</a>
                        </div>
                    </div>
                    <div className="footer__policy__box">
                        <div className="footer__item">
                            <a href="https://www.mars.com/global/policies/note-to-parents/np-russian" className="footer__link" target="_blank" rel="noopener noreferrer">Уведомление для родителей</a>
                        </div>
                        <div className="footer__item">
                            <a href="https://www.mars.com/cookies-russia" className="footer__link" target="_blank" rel="noopener noreferrer">Использование cookies</a>
                        </div>
                    </div>
                    <div className="footer__policy__box">

                        <div className="footer__item">
                            <a href="https://www.mars.com/accessibility-russian" className="footer__link" target="_blank" rel="noopener noreferrer">Доступность</a>
                        </div>
                        <div className="footer__item">
                            <Link
                                className='footer__link'
                                to={start >=0 ? 'coociesform' : '/coociesform'}
                            >
                                Связаться с нами
                            </Link>
                        </div>
                        <button id="ot-sdk-btn" className="ot-sdk-show-settings">Настройки файлов cookie</button>
                    </div>
                </div>
            </div>
        </footer>
    )
}
